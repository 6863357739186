<template>
	<div>
		<v-col md="12" class="font-level-3-bold pb-3 border-bottom-light-grey text-black mt-4 py-0">
			Linked Perishables Items <br />
			<span class="font-level-0 text-muted">
				<v-icon small>mdi-progress-question</v-icon> The Perishables Items consumed during
				maintenance</span
			>
		</v-col>
		<v-col md="12" class="py-0">
			<v-layout>
				<v-flex md6>
					<v-btn
						class="white--text mt-3"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						v-on:click="openDialog()"
					>
						Perishables
					</v-btn>
				</v-flex>
			</v-layout>
			<div class="mt-3 overflow-y border-light-grey" style="max-height: calc(100vh - 337px)">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th width="15%" class="p-2 light-blue-bg">Image</th>
							<th width="55%" class="p-2 light-blue-bg">Name</th>
							<th width="30%" class="p-2 light-blue-bg" colspan="2">Quantity</th>
						</tr>
					</thead>
					<tbody v-if="slectedInventories.length">
						<tr v-for="(row, index) in slectedInventories" :key="index">
							<td class="p-2 border-top-light-grey">
								<ImageTemplate circle style="max-width: 50px" :src="row.image"> </ImageTemplate>
							</td>
							<td class="px-2 pb-3 my-auto font-level-1 text-black border-top-light-grey">
								<p class="m-0 my-auto mt-3">{{ row.text }}</p>
							</td>
							<td class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
								<QuantityInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									placeholder="Quantity"
									v-model="row.quantity"
								></QuantityInput>
							</td>
							<td class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
								<v-btn
									color="red lighten-1"
									class="mt-3"
									v-on:click="removeInventory(index)"
									icon
									depressed
									small
								>
									<v-icon small>mdi-delete</v-icon>
								</v-btn>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="3">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no linked inventory item at the moment.
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</v-col>
		<Dialog :dialog="dialog" :dialog-width="dialogWidth">
			<template v-slot:title> Select Perishables </template>
			<template v-slot:body>
				<v-row class="mx-1">
					<v-col v-if="alphabets.length" md="12" class="alphabets-filter">
						<div class="alphabets-scrollable">
							<v-btn
								:disabled="searching"
								depressed
								v-on:click="getLineItem(null, 'alphabet')"
								:class="{
									'cyan--text': null == alphabet,
									'cyan white--text': null != alphabet,
								}"
								class="custom-bold-button"
								small
								>All</v-btn
							>
							<v-btn
								:disabled="searching"
								depressed
								v-for="(row, index) in alphabets"
								v-on:click="getLineItem(row, 'alphabet')"
								:class="{
									'cyan--text': row == alphabet,
									'cyan white--text': row != alphabet,
								}"
								class="custom-bold-button"
								small
								:key="index"
								>{{ row }}</v-btn
							>
						</div>
					</v-col>
					<v-col md="12">
						<v-layout>
							<v-flex md6 class="fw-700 font-size-17 color-custom-blue">
								<v-btn
									small
									depressed
									:disabled="searching"
									:class="{
										'green white--text': !show_selected,
										'white green--text': show_selected,
									}"
									class="font-size-17 fw-700"
									v-on:click="showAll()"
								>
									{{ getSelectedCount() }}
								</v-btn>

								out of
								<v-btn
									small
									depressed
									:disabled="searching"
									class="font-size-17 fw-700"
									color="white cyan--text"
									v-on:click="showAll()"
								>
									{{ inventoryList.length }}
								</v-btn>
								Perishables Selected
							</v-flex>
							<v-flex md6>
								<v-text-field
									v-model="search"
									label="Search"
									placeholder="Start typing then press enter to search..."
									dense
									filled
									solo
									flat
									:disabled="searching"
									color="cyan"
									append-icon="mdi-magnify"
									clearable
									v-on:click:clear="
										search = null;
										getLineItem(search, 'search');
									"
									v-on:keyup.enter="getLineItem(search, 'search')"
									v-on:click:append="getLineItem(search, 'search')"
								></v-text-field>
							</v-flex>
						</v-layout>
						<br />
						<div class="custom-border-top">
							<br />

							<table class="line-item-multiple-table" width="100%">
								<thead>
									<tr>
										<th class="p-2 light-blue-bg" width="5%">
											<v-checkbox
												:disabled="searching || !inventoryList.length"
												color="cyan"
												v-model="allcheckbox"
												hide-details
												class="m-0 p-0"
												v-on:change="checkAll($event)"
											></v-checkbox>
										</th>
										<th width="15%" class="p-2 light-blue-bg">Image</th>
										<th width="40%" class="p-2 light-blue-bg">Name</th>
										<th width="30%" class="p-2 light-blue-bg text-center">Barcode</th>

										<th width="10%" class="p-2 light-blue-bg">Quantity</th>
									</tr>
								</thead>
								<tbody v-if="inventoryList.length">
									<template v-for="(inventory, index) in inventoryList">
										<tr v-if="checkSelected(inventory)" :key="index">
											<td valign="top" width="5%">
												<v-checkbox
													color="cyan"
													hide-details
													:disabled="searching"
													v-model="inventoryList[index].status"
													v-on:change="validateCheckbox($event)"
													class="ml-1 p-0 mt-2"
												></v-checkbox>
											</td>
											<td class="p-2 border-top-light-grey">
												<ImageTemplate circle style="max-width: 50px" :src="inventory.image"> </ImageTemplate>
											</td>

											<td class="px-2 pb-3 my-auto font-level-1 text-black border-top-light-grey">
												<p class="m-0 my-auto mt-3">{{ inventory.text }}</p>
											</td>
											<td class="px-2 py-2 my-auto font-level-1 text-black border-top-light-grey">
												<div
													v-if="inventory.pico_barcode"
													v-on:click.stop.prevent="openPopup(inventory, 'barcode')"
													class="text-center"
												>
													<label class="font-size-14 mb-1">
														<v-icon size="30">mdi-barcode-scan</v-icon>
													</label>
													<div class="font-size-14 fw-600 mb-1 w-100">
														{{ inventory.pico_barcode }}
													</div>
												</div>
												<div v-else>
													<em class="text-muted">No Barcode</em>
												</div>
											</td>
											<td class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
												<QuantityInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													placeholder="Quantity"
													v-model="inventoryList[index].quantity"
												></QuantityInput>
											</td>
										</tr>
									</template>
								</tbody>
								<tfoot v-else>
									<tr>
										<td colspan="5">
											<p class="m-0 row-not-found text-center fw-500 font-size-16">
												<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
												Uhh... There are no Perishables at the moment.
											</p>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					v-on:click="
						inventoryList = [];
						dialog = false;
					"
					:disabled="searching"
					class="custom-grey-border custom-bold-button"
					>Cancel</v-btn
				>
				<v-btn
					color="cyan white--text"
					class="custom-bold-button"
					v-on:click="() => savePerishables()"
					:disabled="searching"
					>Save</v-btn
				>
			</template>
		</Dialog>
		<v-dialog
			origin="center center"
			v-model="barcodeDialog"
			persistent
			scrollable
			content-class="common-dialog"
			:max-width="500"
		>
			<v-card>
				<v-card-title
					class="headline dialog-custom-header text-uppercase custom-border-bottom custom-header-blue-text white-background"
				>
					{{ barcodeDialogTitle }}
				</v-card-title>
				<v-card-text>
					<div class="text-center mt-2">
						<img v-if="popup_image" class="mb-4" :src="popup_image" :alt="popup_text" width="400px" />
						<h3>{{ popup_text }}</h3>
					</div>
				</v-card-text>
				<v-divider class="my-0"></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn depressed tile v-on:click="barcodeDialog = false">Close </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger, cloneDeep, findIndex, map, filter, find } from "lodash";
import ImageTemplate from "@/view/components/Image";
import QuantityInput from "@/view/components/QuantityInput";
import Dialog from "@/view/components/Dialog";

export default {
	name: "service-template",
	title: "Service Template",
	props: {},
	data() {
		return {
			search: null,
			alphabet: null,
			formValid: true,
			pageLoading: false,
			show_selected: true,
			searching: false,
			allcheckbox: false,
			dialog: false,
			loading: false,
			slectedInventories: [],
			inventoryList: [],
			inventoryListData: [],
			inventory: {},
			barcodeDialog: false,
			barcodeDialogTitle: null,
			popup_image: null,
			popup_text: null,

			alphabets: [
				"a",
				"b",
				"c",
				"d",
				"e",
				"f",
				"g",
				"h",
				"i",
				"j",
				"k",
				"l",
				"m",
				"n",
				"o",
				"p",
				"q",
				"r",
				"s",
				"t",
				"u",
				"v",
				"w",
				"x",
				"y",
				"z",
			],
		};
	},
	components: {
		ImageTemplate,
		QuantityInput,
		Dialog,
	},

	methods: {
		openPopup(data) {
			this.popup_image = data.barcode_image;
			this.popup_text = data.pico_barcode;

			this.barcodeDialogTitle = `${data.text ? data.text : ""} (#${data.barcode})`;
			this.barcodeDialog = true;
			return false;
		},
		getSelectedCount() {
			return filter(this.inventoryList, { status: true }).length;
		},
		checkSelected() {
			return true;
		},
		savePerishables() {
			let selectedData = filter(this.inventoryList, (row) => row.status);
			this.slectedInventories = selectedData;
			this.$emit("selectedPerishables", selectedData);
			this.dialog = false;
			this.inventoryList = [];
		},
		openDialog() {
			this.allcheckbox = false;
			this.inventoryList = map(cloneDeep(this.inventoryListData), (row) => {
				let checkData = find(this.slectedInventories, (row1) => row1.status && row1.uuid == row.uuid);
				if (checkData) {
					row.quantity = checkData.quantity;
					row.status = true;
				} else {
					row.quantity = 1;
					row.status = false;
				}
				return row;
			});
			this.dialog = true;
		},
		selectInventory() {
			const inventory = cloneDeep(this.inventory);
			this.inventory = new Object({});
			let preInventory = findIndex(this.slectedInventories, { id: inventory.value });
			if (preInventory != -1) {
				this.slectedInventories[preInventory].quantity =
					toSafeInteger(this.slectedInventories[preInventory].quantity) + 1;
			} else {
				this.slectedInventories.push({
					id: inventory.value,
					uuid: inventory.uuid,
					name: inventory.text,
					quantity: 1,
				});
			}
		},
		removeInventory(index) {
			let inventoryArr = this.slectedInventories;
			inventoryArr.splice(index, 1);
			this.slectedInventories = inventoryArr;
		},
		validateCheckbox() {
			const len = filter(this.inventoryList, { status: true }).length;
			const default_len = this.inventoryList.length;
			if (default_len) {
				if (default_len == len) {
					this.allcheckbox = true;
				} else {
					this.allcheckbox = false;
				}
			} else {
				this.allcheckbox = false;
			}
		},
		checkAll(event) {
			if (event) {
				for (let i = this.inventoryList.length - 1; i >= 0; i--) {
					this.inventoryList[i].status = true;
				}
			} else {
				for (let z = this.inventoryList.length - 1; z >= 0; z--) {
					this.inventoryList[z].status = false;
				}
			}
		},
	},
	mounted() {
		this.inventoryListData = this.localDB("inventories", []);
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 70);
		},
	},
};
</script>
