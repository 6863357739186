<template>
	<v-sheet class="group" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="2" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.group_status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="10" class="py-1 my-auto text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-layout class="justify-end">
						<div class="mx-2" style="max-width: 300px">
							<date-range-picker
								v-on:click:clear="
									s_search['date-range'] = [];
									searchOrders();
								"
								hide-details
								hide-top-margin
								clearable
								v-model="s_search['date-range']"
							></date-range-picker>
						</div>
						<div class="mx-2" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									s_search['service-by'] = [];
									searchOrders();
								"
								clearable
								:items="supplier_list"
								hide-details
								custom-class="pt-0"
								placeholder="Service By"
								multiple
								v-model="s_search['service-by']"
							></select-input>
						</div>
						<div class="mx-2" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									s_search['type'] = [];
									searchOrders();
								"
								clearable
								:items="item_type_items"
								hide-details
								custom-class="pt-0"
								placeholder="Type"
								multiple
								v-model="s_search['type']"
							></select-input>
						</div>
						<div class="d-flex">
							<v-btn
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								v-on:click="searchOrders()"
								><v-icon center> mdi-magnify </v-icon></v-btn
							>
							<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-if="getPermission('service:cerate')"
										color="blue darken-4 text-white"
										v-on="on"
										v-bind="attrs"
										class="mx-2"
										depressed
										tile
										><v-icon left> mdi-plus </v-icon> Create</v-btn
									>
								</template>

								<v-list>
									<v-list-item
										v-on:click="
											selectRelateddialog = true;
											requestType = 'start';
										"
									>
										<v-list-item-title>
											<v-icon small left color="blue darken-4">mdi-clock-check-outline</v-icon>
											Start</v-list-item-title
										>
									</v-list-item>
									<v-list-item
										v-on:click="
											selectRelateddialog = true;
											requestType = 'scheduled';
										"
									>
										<v-list-item-title>
											<v-icon small left color="blue darken-4">mdi-calendar-clock</v-icon>
											Schedule</v-list-item-title
										>
									</v-list-item>
								</v-list>
							</v-menu>
							<v-menu
								bottom
								left
								origin="center center"
								transition="slide-y-transition"
								rounded="0"
								offset-y
								max-height="400px"
								:close-on-content-click="false"
								content-class="white-background"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										color="blue darken-4"
										class="text-white"
										tile
										:disabled="pageLoading"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon>mdi-table-edit</v-icon>
									</v-btn>
								</template>
								<Draggable
									tag="ul"
									v-model="draggableThead"
									class="draggable-group"
									handle=".draggable-drag-icon"
									v-on:change="updateTable('group')"
								>
									<template v-for="cols in draggableThead">
										<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
											<v-checkbox
												dense
												v-model="draggableTheadShow"
												v-bind:value="cols.key"
												:label="cols.name"
												:disabled="cols.fixed || pageLoading"
												color="blue"
												hide-details
												class="mt-0 mb-0"
												v-on:change="updateTableVisiblity('group')"
											></v-checkbox>
											<v-icon
												v-if="!pageLoading && !cols.fixed"
												class="draggable-action draggable-drag-icon"
												right
												color="blue"
												>mdi-drag</v-icon
											>
											<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
										</li>
									</template>
								</Draggable>
							</v-menu>
						</div>
					</v-layout>
					<table width="100%" v-if="false" style="table-layout: fixed">
						<tr>
							<td width="26%" class="px-2">
								<date-range-picker
									v-on:click:clear="
										s_search['date-range'] = [];
										searchOrders();
									"
									hide-details
									hide-top-margin
									clearable
									v-model="s_search['date-range']"
								></date-range-picker>
							</td>
							<td width="22%" class="px-2">
								<select-input
									v-on:click:clear="
										s_search['service-by'] = [];
										searchOrders();
									"
									clearable
									:items="supplier_list"
									hide-details
									custom-class="pt-0"
									placeholder="Service By"
									multiple
									v-model="s_search['service-by']"
								></select-input>
							</td>
							<td width="22%" class="px-2">
								<select-input
									v-on:click:clear="
										s_search['type'] = [];
										searchOrders();
									"
									clearable
									:items="item_type_items"
									hide-details
									custom-class="pt-0"
									placeholder="Type"
									multiple
									v-model="s_search['type']"
								></select-input>
							</td>
							<td width="30%" align="right" class="px-2">
								<v-btn
									color="blue darken-4 text-white"
									class="mx-2"
									depressed
									tile
									v-on:click="searchOrders()"
									><v-icon left> mdi-magnify </v-icon> Search</v-btn
								>
								<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-if="getPermission('service:cerate')"
											color="blue darken-4 text-white"
											v-on="on"
											v-bind="attrs"
											class="mx-2"
											depressed
											tile
											><v-icon left> mdi-plus </v-icon> Create</v-btn
										>
									</template>

									<v-list>
										<v-list-item
											v-on:click="
												selectRelateddialog = true;
												requestType = 'start';
											"
										>
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-clock-check-outline</v-icon>
												Start</v-list-item-title
											>
										</v-list-item>
										<v-list-item
											v-on:click="
												selectRelateddialog = true;
												requestType = 'scheduled';
											"
										>
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-calendar-clock</v-icon>
												Schedule</v-list-item-title
											>
										</v-list-item>
									</v-list>
								</v-menu>
								<v-menu
									bottom
									left
									origin="center center"
									transition="slide-y-transition"
									rounded="0"
									offset-y
									max-height="400px"
									:close-on-content-click="false"
									content-class="white-background"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											color="blue darken-4"
											class="text-white"
											tile
											:disabled="pageLoading"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon>mdi-table-edit</v-icon>
										</v-btn>
									</template>
									<Draggable
										tag="ul"
										v-model="draggableThead"
										class="draggable-group"
										handle=".draggable-drag-icon"
										v-on:change="updateTable('group')"
									>
										<template v-for="cols in draggableThead">
											<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
												<v-checkbox
													dense
													v-model="draggableTheadShow"
													v-bind:value="cols.key"
													:label="cols.name"
													:disabled="cols.fixed || pageLoading"
													color="blue"
													hide-details
													class="mt-0 mb-0"
													v-on:change="updateTableVisiblity('group')"
												></v-checkbox>
												<v-icon
													v-if="!pageLoading && !cols.fixed"
													class="draggable-action draggable-drag-icon"
													right
													color="blue"
													>mdi-drag</v-icon
												>
												<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
											</li>
										</template>
									</Draggable>
								</v-menu>
							</td>
						</tr>
					</table>
				</template>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="12">
				<v-layout class="page-summary show" style="height: 90px !important">
					<v-flex class="summary-details box-light-blue" md4>
						<v-icon color="blue">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">All Service</div>
						<div class="number blue--text">{{ serviceCount.allService }}</div>
					</v-flex>
					<v-flex class="summary-details box-light-cyan" md4>
						<v-icon color="cyan">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">Started</div>
						<div class="number cyan--text">{{ serviceCount.Started }}</div>
					</v-flex>
					<v-flex class="summary-details box-light-orange" md4>
						<v-icon color="orange">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">Scheduled</div>
						<div class="number orange--text">{{ serviceCount.Scheduled }}</div>
					</v-flex>
					<v-flex class="summary-details box-light-green" md4>
						<v-icon color="green">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">Completed</div>
						<div class="number green--text">{{ serviceCount.Completed }}</div>
					</v-flex>
					<v-flex class="summary-details box-light-red" md4>
						<v-icon color="red">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">Overdue</div>
						<div class="number red--text">{{ serviceCount.Completed }}</div>
					</v-flex>
				</v-layout>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Asset #,Asset Name"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="service"
			delete-endpoint="asset/service/"
			:dialog-update="true"
			:dialog-view="true"
			v-on:reload:content="filterRows"
			v-on:update:dialog="updateDialog($event)"
			v-on:view:dialog="viewDialog($event)"
			delete-note="All transactions of this group will also be deleted."
		></Table>
		<SelectRequested
			v-on:selectRelatedTo="selectRelatedTo"
			v-on:close="selectRelateddialog = false"
			:checkout-dialog="selectRelateddialog"
			:asset-only="true"
		></SelectRequested>
		<ServiceStartTemplate
				v-if="serviceDialog"
				type="Asset"
				endpoint="asset"
				:type-uuid="relatedUuid"
				v-on:success="
					serviceDialog = false;
					getListing();
				"
				v-on:close="serviceDialog = false"
				:service-dialog="serviceDialog"
			></ServiceStartTemplate>
			<ServiceScheduleTemplate
				v-if="serviceScheduleDialog"
				type="Asset"
				endpoint="asset"
				:type-uuid="relatedUuid"
				v-on:success="
					serviceScheduleDialog = false;
					getListing();
				"
				v-on:close="serviceScheduleDialog = false"
				:service-dialog="serviceScheduleDialog"
			></ServiceScheduleTemplate>
		<!-- <ServiceStartTemplate
			type="Asset"
			endpoint="asset"
			:type-uuid="relatedUuid"
			:service-array="serviceArr"
			v-on:success="
				serviceDialog = false;
				getListing();
			"
			v-on:close="serviceDialog = false"
			:service-dialog="serviceDialog"
		></ServiceStartTemplate> -->
		<!-- <ServiceScheduleTemplate
			type="Asset"
			endpoint="asset"
			:type-uuid="relatedUuid"
			:service-array="serviceArr"
			v-on:success="
				serviceScheduleDialog = false;
				getListing();
			"
			v-on:close="serviceScheduleDialog = false"
			:service-dialog="serviceScheduleDialog"
		></ServiceScheduleTemplate> -->
		<Dialog :dialog="detailDialog" :dialog-width="dialogWidth" dense>
			<template v-slot:title>
				<v-layout>
					<v-flex md4 class="my-auto"> {{ service.service_type }} Service </v-flex>
					<v-flex md8 class="text-right">
						<template v-if="getPermission('service:cerate')">
							<v-btn
								v-if="service.status == 3"
								color="blue darken-4 text-white"
								class="mr-2"
								depressed
								tile
								v-on:click="startScheduledService"
								><v-icon small left>mdi-hammer-wrench</v-icon>Start</v-btn
							>
						</template>
						<template v-if="getPermission('service:update')">
							<v-btn
								v-if="service.status == 1"
								v-on:click="serviceCompleteDialog = true"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								><v-icon small left>mdi-format-list-checks</v-icon>Complete</v-btn
							>
						</template>
						<template v-if="getPermission('service:update')">
							<v-btn
								v-if="service.status == 1"
								v-on:click="serviceExtendDialog = true"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								><v-icon small left>mdi-share-all-outline</v-icon>Extend</v-btn
							>
						</template>
						<v-btn
							v-on:click="
								detailDialog = false;
								serviceTab = 'detail';
							"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-close</v-icon>Close</v-btn
						>
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<div>
					<v-tabs
						v-model="serviceTab"
						background-color="transparent"
						color="blue"
						class="custom-tab-transparent"
						active-class="blue darken-4 text-white"
						hide-slider
					>
						<v-tab href="#detail">
							<span class="tab-svg-icon mr-2">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/services.svg')" />
								<!--end::Svg Icon-->
							</span>
							Details
						</v-tab>
						<v-tab href="#linked-inventory" v-on:click="getLinkedInventories()">
							<span class="tab-svg-icon mr-2">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/inventory.svg')" />
								<!--end::Svg Icon-->
							</span>
							Linked Perishables
						</v-tab>
						<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab>
						<v-tab href="#comment"> <v-icon small left>mdi-comment-multiple</v-icon> Comments</v-tab>
					</v-tabs>
					<v-tabs-items v-model="serviceTab">
						<v-tab-item value="detail">
							<div class="mx-4 mb-4">
								<div class="overflow-y" style="max-height: 600px; min-height: 400px">
									<v-layout class="p-4 border-bottom-light-grey min-height-57px">
										<v-flex class="font-level-3-bold my-auto">
											<span class="detail-svg-icon mr-2">
												<!--begin::Svg Icon-->
												<inline-svg :src="$assetURL('media/custom-svg/services-color.svg')" />
												<!--end::Svg Icon-->
											</span>
											Service Details
										</v-flex>
									</v-layout>
									<table width="100%" class="detail-table scattered-table">
										<tr>
											<th class="p-2" width="250px">Service Type</th>
											<td class="font-level-1 p-2">
												<ShowValue :object="service" object-key="service_type" label="service type"></ShowValue>
											</td>
											<th class="p-2" width="250px">Service Start Date (expected)</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="expected_start_date_formatted"
													label="service start date (expected)"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Cost</th>
											<td class="font-level-1 p-2">
												<ShowValue :object="service" object-key="cost_formatted" label="Cost"></ShowValue>
											</td>
											<th class="p-2" width="250px">Service End Date (expected)</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="expected_end_date_formatted"
													label="Service End Date (expected)"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Service By</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="service_by_formatted"
													label="Service By"
												></ShowValue>
												(<ShowValue
													:object="service"
													v-if="service.service_by == 1"
													object-key="supplier_display_name"
													label="supplier"
												></ShowValue>
												<ShowValue
													:object="service"
													v-else
													object-key="member_display_name"
													label="member"
												></ShowValue
												>)
											</td>
											<th class="p-2" width="250px">Service Start Date (actual)</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="actual_start_date_formatted"
													label="service start date (actual)"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Created By</th>
											<td class="font-level-1 p-2">
												<ShowValue :object="service" object-key="created_by" label="Created By"></ShowValue>
											</td>
											<th class="p-2" width="250px">Service End Date (actual)</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="actual_end_date_formatted"
													label="Service End Date (actual)"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Description</th>
											<td class="font-level-1 p-2" colspan="3">
												<ShowValue :object="service" object-key="description" label="Description"></ShowValue>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</v-tab-item>
						<v-tab-item value="linked-inventory">
							<div class="mx-4 mb-4">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex md6 class="font-level-3-bold my-auto">
										<table width="100%">
											<tr>
												<td>
													<span class="detail-svg-icon mr-2">
														<!--begin::Svg Icon-->
														<inline-svg :src="$assetURL('media/custom-svg/inventory-color.svg')" />
														<!--end::Svg Icon-->
													</span>
												</td>
												<td>
													<p class="m-0 mb-2">Linked Perishables</p>
													<span class="font-small text-muted">
														<v-icon small>mdi-progress-question</v-icon> Items displayed here were consumed during
														the service.</span
													>
												</td>
											</tr>
										</table>
									</v-flex>
									<v-flex md6 class="font-level-3-bold my-auto text-right">
										<v-btn
											v-if="service.status != 2"
											v-on:click="inventoryDialog = true"
											color="blue darken-4 text-white"
											depressed
											tile
											><v-icon small left>mdi-playlist-plus</v-icon>Link Perishables Items</v-btn
										>
									</v-flex>
								</v-layout>
								<div
									class="overflow-y border-light-grey"
									style="max-height: calc(100vh - 250px); min-height: 400px"
								>
									<table width="100%" class="detail-table scattered-table table-head-sticky">
										<thead>
											<tr>
												<th class="p-2 light-blue-bg font-level-1-bold">Perishables #</th>
												<th class="p-2 light-blue-bg font-level-1-bold">Name</th>
												<th class="p-2 light-blue-bg font-level-1-bold">Quantity</th>
												<th v-if="false" class="p-2 light-blue-bg font-level-1-bold">Location</th>
												<th class="p-2 light-blue-bg font-level-1-bold">Date Added</th>
											</tr>
										</thead>
										<tbody v-if="linkedInventories.length">
											<tr v-for="(row, index) in linkedInventories" :key="index">
												<td class="border-top-light-grey border-right-light-grey font-level-1 p-2">
													<Chip :text="row.inventory_barcode" color="cyan"></Chip>
												</td>
												<td class="border-top-light-grey border-right-light-grey font-level-1 p-2">
													<ShowValue :object="row" object-key="inventory_name" label="name"></ShowValue>
												</td>
												<td class="border-top-light-grey border-right-light-grey font-level-1 p-2">
													<ShowValue :object="row" object-key="quantity" label="quantity"></ShowValue>
												</td>
												<td v-if="false" class="border-top-light-grey border-right-light-grey font-level-1 p-2">
													<ShowValue :object="row" object-key="location_name" label="location"></ShowValue>
												</td>
												<td class="border-top-light-grey font-level-1 p-2">
													<TableDateTime
														v-if="row.created_at"
														:human-format="row.created_at"
														:system-format="row.added_at"
													></TableDateTime>
												</td>
											</tr>
										</tbody>
										<tfoot v-else>
											<tr>
												<td colspan="5">
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no linked perishables at the moment.
													</p>
												</td>
											</tr>
										</tfoot>
									</table>
								</div>
							</div>
						</v-tab-item>
						<v-tab-item value="file">
							<Files
								class="mx-4"
								type-text="Service Files"
								type="service"
								:type-uuid="service.uuid"
								:type-id="serviceId"
							></Files>
						</v-tab-item>
						<v-tab-item value="comment">
							<Comments
								class="mx-4"
								type-text="Service"
								type="service"
								:type-uuid="service.uuid"
							></Comments>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</template>
		</Dialog>
		<ServiceExtendTemplate
			type="Asset"
			endpoint="asset"
			:type-uuid="assetUuid"
			:service-uuid="service.uuid"
			v-on:success="
				serviceExtendDialog = false;
				detailDialog = false;
				serviceTab = 'detail';
				reload_now = true;
				getListing();
			"
			v-on:close="serviceExtendDialog = false"
			:service-dialog="serviceExtendDialog"
		></ServiceExtendTemplate>
		<ServiceCompleteTemplate
			type="Asset"
			endpoint="asset"
			:type-uuid="assetUuid"
			:service-uuid="service.uuid"
			v-on:success="
				serviceCompleteDialog = false;
				detailDialog = false;
				serviceTab = 'detail';
				reload_now = true;
				getListing();
			"
			v-on:close="serviceCompleteDialog = false"
			:service-dialog="serviceCompleteDialog"
		></ServiceCompleteTemplate>
		<Dialog :dialog="inventoryDialog" :dialog-width="dialogWidth - 200" dense>
			<template v-slot:title> Link Perishables Items </template>
			<template v-slot:body>
				<LinkAssetInventoryItem v-model="inventories" class="mx-4 mb-4"></LinkAssetInventoryItem>
			</template>
			<template v-slot:action>
				<v-btn v-on:click="closeLinkInventory()" color="blue darken-4 text-white" depressed tile
					>Close</v-btn
				>
				<v-btn v-on:click="linkInventory()" color="blue darken-4 text-white" depressed tile
					>Link Perishables</v-btn
				>
			</template>
		</Dialog>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import ShowValue from "@/view/components/ShowValue";
import SelectRequested from "@/view/components/Select-Checkout-Requested";
import ServiceStartTemplate from "@/view/components/ServiceStartTemplate";
import ServiceScheduleTemplate from "@/view/components/ServiceScheduleTemplate";
import { mapGetters } from "vuex";
import { filter, toSafeInteger } from "lodash";
import ServiceExtendTemplate from "@/view/components/ServiceExtendTemplate";
import ServiceCompleteTemplate from "@/view/components/ServiceCompleteTemplate";
import Dialog from "@/view/components/Dialog";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import ApiService from "@/core/services/api.service";
import { AssetServiceLinkInventory, GetAssetServiceLinkInventory } from "@/core/lib/asset.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import LinkAssetInventoryItem from "@/view/components/LinkAssetInventoryItem";
import Chip from "@/view/components/Chip";
import TableDateTime from "@/view/components/TableDateTime";
import SelectInput from "@/view/components/SelectInput";
import DateRangePicker from "@/view/components/DateRangePicker";
import ObjectPath from "object-path";

export default {
	name: "group-listing",
	title: "Listing Group",
	mixins: [ListingMixin],
	components: {
		SelectRequested,
		Dialog,
		Files,
		Comments,
		ShowValue,
		Chip,
		SelectInput,
		DateRangePicker,
		TableDateTime,
		ServiceCompleteTemplate,
		ServiceStartTemplate,
		ServiceExtendTemplate,
		ServiceScheduleTemplate,
		LinkAssetInventoryItem,
	},
	methods: {
		getServiceCount() {
			ApiService.setHeader();
			ApiService.get(`service-count`)
				.then(({ data }) => {
					this.serviceCount = data;
				})
				.catch(() => {});
		},
		searchOrders() {
			this.$router
				.replace({
					name: this.$route.name,
					query: {
						...this.$route.query,
						...this.s_search,
					},
				})
				.then(() => {
					this.getListing();
				})
				.catch(() => {});
		},
		selectRelatedTo(data) {
			this.relatedUuid = data.id;
			this.selectRelateddialog = false;
			if (this.requestType == "start") {
				this.serviceDialog = true;
			} else {
				this.serviceScheduleDialog = true;
			}
		},
		updateDialog(uuid) {
			this.uuid = uuid;
			this.getServiceDetail();
			//this.dialog = true;
		},
		viewDialog(uuid) {
			let service = filter(this.tbody, { uuid: uuid });
			if (service && service[0]) {
				this.service = service[0];
				this.serviceId = service[0].id;
				this.assetUuid = this.service.asset_uuid;
				this.detailDialog = true;
				this.serviceTab = "detail";
			}
		},
		getLinkedInventories() {
			if (!this.service.uuid) {
				return false;
			}

			GetAssetServiceLinkInventory(this.service.asset_uuid, this.service.uuid)
				.then((data) => {
					this.linkedInventories = data;
				})
				.catch((error) => {
					this.logError(error);
				});
		},
		startScheduledService() {
			this.pageLoading = true;
			ApiService.patch(`asset/${this.assetUuid}/service/${this.service.uuid}/start`)
				.then(() => {
					this.detailDialog = false;
					this.serviceTab = "detail";
					this.getListing();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getServiceDetail() {
			this.pageLoading = true;
			ApiService.get(`asset/service/${this.uuid}`)
				.then(({ data }) => {
					this.serviceArr = data;
					if (data.status == 1) {
						this.serviceDialog = true;
					} else if (data.status == 3) {
						this.serviceScheduleDialog = true;
					}
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		closeLinkInventory() {
			this.inventoryList = [];
			this.inventories = [];
			this.inventoryDialog = false;
			this.searchLoading = false;
			this.formLoading = false;
			this.menuSearch = false;
			this.search = null;
		},
		linkInventory() {
			if (!this.service.uuid) {
				return false;
			}

			if (!this.inventories.length) {
				this.$store.commit(
					SET_ERROR,
					this.errors.concat([{ model: true, message: "Error ! Add atleast one inventory." }])
				);
				return false;
			}

			this.formLoading = true;
			AssetServiceLinkInventory(this.service.asset_uuid, this.service.uuid, {
				inventory: this.inventories,
			})
				.then((data) => {
					this.linkedInventories = data;
					this.closeLinkInventory();
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Perishables has been successfully linked to service." },
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.formLoading = false;
				});
		},
	},
	data() {
		return {
			uuid: null,
			reload_now: false,
			supplier_list: [
				{ text: "Supplier", value: "supplier" },
				{ text: "Staff", value: "incharge-officer" },
			],
			item_type_items: [
				{ text: "Repair", value: "repair" },
				{ text: "Standard", value: "standard" },
				{ text: "Warranty", value: "warranty" },
				{ text: "Other", value: "other" },
			],
			requestType: null,
			assetUuid: null,
			linkedInventories: [],
			s_search: {
				"date-range": [],
				"service-by": [],
				type: [],
			},
			relatedUuid: null,
			serviceArr: null,
			serviceDialog: false,
			serviceCompleteDialog: false,
			serviceExtendDialog: false,
			inventoryDialog: false,
			serviceTab: "detail",
			detailDialog: false,
			service: {},
			serviceId: 0,
			serviceScheduleDialog: false,
			selectRelateddialog: false,
			dialog: false,
			pageTitle: "Services",
			pageBreadcrumbs: [{ text: "Services", disabled: true }],
			endpoint: "services/asset",
			defaultFilter: {},
			inventoryList: [],
			inventories: [],
			searchLoading: false,
			formLoading: false,
			menuSearch: false,
			search: null,
			serviceCount: [],
		};
	},
	computed: {
		...mapGetters(["tbody"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 65);
		},
	},
	mounted() {
		this.s_search["date-range"] = ObjectPath.get(this.$route, "query.date-range", []);
		this.s_search["service-by"] = ObjectPath.get(this.$route, "query.service-by", []);
		this.s_search["type"] = ObjectPath.get(this.$route, "query.type", []);
		this.getServiceCount();
	},
};
</script>
